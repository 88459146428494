import "./index.scss";
import { Spin, message } from "antd";
import { ReactSVG } from "react-svg";
import LoadingSvg from "../../assets/loading.svg";
import React, { useEffect, useRef } from "react";
import userApi from "../../apis/user/index";

const MenuLoadingPage = (props) => {
  const { setPageLoading } = props;

  const getMenus = () => {
    userApi.getMenus().then((res) => {
      if (res.code === 200) {
        const menusItem = res?.data?.find((_) => _.menu_code === "CDD_PROD");
        if (!menusItem) {
          // message.error('未匹配到本项目的目录数据!');
          const findItem = (res.data || [])?.find((i) => i.menu_url);
          //订阅过项目 跳到订阅的项目里
          if (findItem) {
            //findItem.menu_url
            window.location.href = localStorage.getItem("other_sub_url");
          } else {
            //没订阅过 跳到详情页
            window.location.href = localStorage.getItem("sub_url");
          }
          return;
        }
      } else {
        message.error(res.msg);
      }
      setPageLoading(false)
    });
  };

  useEffect(() => {
    getMenus();
  },[]);

  return (
    <Spin
      className="mySpinLoading"
      tip={props.tip}
      indicator={
        <ReactSVG
          src={LoadingSvg}
          className="anticon-spin anticon myLoadingIcon"
        />
      }
    ></Spin>
  );
};
export default MenuLoadingPage;
