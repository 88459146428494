import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  message,
  Table,
  Tooltip,
  Checkbox,
  Input,
  Select,
  Popover,
  Avatar,
} from "antd";
import MyEmpty from "../../../../../components/Empty";
import { ReactSVG } from "react-svg";
import icon1 from "../../../../../assets/上传2.svg";
import icon2 from "../../../../../assets/添加.svg";
import icon3 from "../../../../../assets/复制.svg";
import UploadFileDrawer from "../../../../../components/UploadFileDrawer";
import projectApi from "../../../../../apis/project";
import {
  getAdminsDom,
  getContactBusinessDom,
  guid,
  judgmentDuplicateArray,
  uniqueArray,
  verifyAdmin,
} from "../../../../../unit/unit";
import AddDrawer from "../AddDrawer";
import { showInfoFunc } from "../../../../../components/MyTable";
import MessageModal from "../../../../../components/MessageModal";
import { useGetState, useSize } from "ahooks";
import {
  PlusCircleOutlined,
  VerticalAlignTopOutlined,
  UndoOutlined,
  CheckOutlined
} from "@ant-design/icons";
import deleteIcon from "../../../../../assets/delete.svg";
import HeaderTitle from "../../../../../components/HeaderTitle";
import nProgress from "nprogress";
import "nprogress/nprogress.css";
import CopyModal from "../CopyModal";
import UploadModal from "../UploadMadel";
import useUrlState from "@ahooksjs/use-url-state";
import { useSelector } from "react-redux";

const CreateContent = (props) => {
  const { id } = useParams();

  const {
    setIsCreate,
    defaultRules = null,
    getDataFunc,
    defaultData = [],
    isFirst = false,
  } = props;

  const [selectItemId, setSelectItemId, getSelectItemId] = useGetState(null);

  const [stepIndex, setStepIndex] = useState(1);
  const [verificationList, setVerificationList, getVerificationList] =
    useGetState([]);

  const [fileVisible, setFileVisible] = useState(false);
  const [upLoading, setUpLoading] = useState(false);

  const [addVisible, setAddVisible] = useState(false);
  const [copyVisible, setCopyVisible] = useState(false);

  const ref = useRef(null);
  const size = useSize(ref);
  const myInfo = useSelector((state) => state.User.userInfo);
  const admins = useSelector((state) => state.User.admins);

  const rangeOptionsList = [
    "核查对象之间",
    "核查对象同尽调公司之间",
    "核查对象同关联方之间",
  ];

  const associationTypeOptions = [
    "股权关系/历史股权关系",
    "任职关系/历史任职关系",
    "相似邮箱",
    "相似电话",
    "相似网址",
    "相近地址",
    "相同案件",
    "相同专利",
    "相同商标",
    "相同作品著作",
    "相同软件著作",
  ];

  const [rangeList, setRangeList] = useState([]);
  const [shareholding, setShareholding] = useState(0.01);
  const [hierarchy, setHierarchy] = useState(3);
  const [associationTypes, setAssociationTypes] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");

  const [isChecking, setIsChecking] = useState(false);

  const [initialRules, setInitialRules] = useState({});

  const [upData, setUpData] = useState(null);
  const [uploadVisible, setUploadVisible] = useState(false);

  const [state, setState] = useUrlState({});

  const [max, setMax, getMax] = useGetState(0);

  useEffect(() => {
    nProgress.done();
    return () => nProgress.start();
  }, []);

  const isDuplicateMessage = () => {
    const messages = document.querySelectorAll(".ant-message-warning");
    // 计算数量
    const messageCount = messages?.length;
    const isDuplicate = judgmentDuplicateArray(getVerificationList(), [
      "id",
      "s_type",
      "name",
      "type",
    ]);
    if (isDuplicate && messageCount === 0) {
      setTimeout(() => {
        message.warning(
          "存在重复核查对象（会占用核查对象数量），建议删除！"
        );
      }, 1000);
    }
  }

  const getMaxNum = () => {
    projectApi.getLicenseNums('["SUS_REL_PARTY_CHECK_OBJ_NUM"]').then((res) => {
      let max = 50;
      if (res.code === 200) {
        max = (res.data?.SUS_REL_PARTY_CHECK_OBJ_NUM || [])?.at(1) || 0;
      }
      setMax(max);
      setTimeout(() => {
        if (defaultData?.length > 0) {
          let prev = JSON.parse(
            JSON.stringify(
              defaultData?.map((item) => ({
                key: Math.random(),
                s_type: item.s_type,
                id: item.id,
                name: item.name,
                type: item.type,
              })) || []
            )
          );
          if (prev?.length > getMax()) {
            message.warning({
              content: verifyAdmin(myInfo) ? (
                <span>
                  核查对象数量已达上限{getMax()}个，如需支持更多核查对象请
                  {getContactBusinessDom()}
                  升级订阅方案
                </span>
              ) : (
                <span>
                  核查对象数量已达上限{getMax()}
                  个，如需支持更多核查对象请联系
                  {getAdminsDom()}
                  升级订阅方案
                </span>
              ),
            });
            prev = prev?.slice(0, getMax());
          }
          setVerificationList([...prev]);
          setTimeout(() => {
            isDuplicateMessage()
          }, 100);
        }
      }, 100);
    });
  };

  useEffect(() => {
    getInitialRules();
    getMaxNum();
  }, []);

  // useEffect(() => {
  //   if (defaultData?.length > 0) {
  //     let prev = JSON.parse(
  //       JSON.stringify(
  //         defaultData?.map((item) => ({
  //           key: Math.random(),
  //           s_type: item.s_type,
  //           id: item.id,
  //           name: item.name,
  //           type: item.type,
  //         })) || []
  //       )
  //     );
  //     if (prev?.length > getMax()) {
  //       message.warning({
  //         content: verifyAdmin(myInfo) ? (
  //           <span>
  //             核查对象数量已达上限{getMax()}个，如需支持更多核查对象请
  //             {getContactBusinessDom()}
  //             升级订阅方案
  //           </span>
  //         ) : (
  //           <span>
  //             核查对象数量已达上限{getMax()}
  //             个，如需支持更多核查对象请联系
  //             {getAdminsDom()}
  //             升级订阅方案
  //           </span>
  //         ),
  //       });
  //       prev = prev?.slice(0, getMax());
  //     }
  //     setVerificationList([...prev]);
  //   }
  // }, [defaultData]);

  //获取默认核查规则
  const getInitialRules = () => {
    projectApi.getSuspectDefaultRules().then((res) => {
      if (res.code === 200) {
        setInitialRules(res.data);
        initRules(defaultRules ? defaultRules : res.data);
      } else {
        setInitialRules({});
        message.error(res.message);
      }
    });
  };

  //设置完成 进行核查
  const onFinish = () => {
    if (rangeList.length === 0) {
      message.info("请选择核查范围");
      return;
    }
    if (associationTypes.length === 0) {
      message.info("请选择核查关联类型");
      return;
    }
    if (inputError) {
      message.info("请输入正确的核查对象穿透股权比例");
      return;
    }
    if (!shareholding) {
      message.info("请输入核查对象穿透股权比例");
      return;
    }
    if (!hierarchy) {
      message.info("请选择核查对象穿透层级");
      return;
    }
    const obj = {};
    Object.keys(initialRules)?.forEach((key) => {
      let value = "";
      if (key === "核查范围") {
        value = rangeList;
      } else if (key === "核查对象穿透股权比例") {
        value = Number(shareholding);
      } else if (key === "核查对象穿透层级") {
        value = hierarchy;
      } else if (key === "核查关系类型") {
        value = associationTypes;
      }
      obj[key] = {
        ...(initialRules[key] || {}),
        value,
      };
    });

    checkUp(obj);
  };

  const initRules = (rules) => {
    const _rules = JSON.parse(JSON.stringify(rules));
    setRangeList(_rules["核查范围"]?.value || []);
    setShareholding(_rules["核查对象穿透股权比例"]?.value || 0.01);
    setHierarchy(_rules["核查对象穿透层级"]?.value || 3);
    setAssociationTypes(_rules["核查关系类型"]?.value || []);
    setInputError(false);
    setInputErrorMsg("");
  };

  //下一步
  const nextHandle = () => {
    if (stepIndex === 1) {
      if (verificationList?.length === 0) {
        message.info("请先添加核查对象");
        return;
      }
      setStepIndex(2);
    } else if (stepIndex === 2) {
      onFinish();
    }
  };

  //上一步
  const prevHandle = () => {
    if (stepIndex !== 1) {
      setStepIndex(1);
    } else {
      setIsCreate(false);
    }
  };

  //核查
  const checkUp = (rules) => {
    let validData = verificationList.filter((_) => !_.is_valid) || [];
    validData = uniqueArray(validData, ["id", "s_type", "name", "type"]);
    MessageModal({
      type: "warning",
      noIcon: true,
      description: "确定要开始核查吗?",
      okText: "核查",
      message: "系统核查时间较长，您可以先进行其他工作，待核查完成系统会通知您",
      title: "开始核查",
      paddingTop: 30,
      onOk: () => {
        setIsChecking(true);
        projectApi
          .setCheck(id, JSON.stringify(validData), JSON.stringify(rules || {}))
          .then((res) => {
            if (res.code === 200) {
              setIsCreate(false);
              if (state.id) {
                setState({ id: undefined });
              } else {
                getDataFunc();
              }
            } else {
              message.error(res.message);
            }
            setIsChecking(false);
          });
      },
    });
  };

  //点击按钮回调
  const handleFunc = (type) => {
    if (type === "上传") {
      setFileVisible(true);
    } else if (type === "新增") {
      setAddVisible(true);
    } else if (type === "复制") {
      setCopyVisible(true);
    }
  };

  //上传成功回调
  const uploadFileSuccess = (files) => {
    setUpLoading(true);
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file[]", file?.originFileObj);
    });
    projectApi.uploadCheckFiles(id, formData).then((res) => {
      if (res.code === 200) {
        setUpData(res.data);
        setFileVisible(false);
        setUploadVisible(true);
      } else {
        message.error(res.message);
      }
      setUpLoading(false);
    });
  };

  //上传成功点击确定
  const uploadSuccess = () => {
    let nodes =
      upData?.success_data?.map((item) => {
        item.key = guid();
        return item;
      }) || [];

    setVerificationList((prev) => {
      // prev = uniqueArray(
      //   [...prev, ...nodes],
      //   ["id", "s_type", "name", "type", "msg","is_valid"]
      // );
      prev = [...prev, ...nodes];
      if (prev?.length > getMax()) {
        message.warning({
          content: verifyAdmin(myInfo) ? (
            <span>
              核查对象数量已达上限{getMax()}个，如需支持更多核查对象请
              {getContactBusinessDom()}
              升级订阅方案
            </span>
          ) : (
            <span>
              核查对象数量已达上限{getMax()}
              个，如需支持更多核查对象请联系
              {getAdminsDom()}
              升级订阅方案
            </span>
          ),
        });
        prev = prev?.slice(0, getMax());
      }
      return [...prev];
    });
    setTimeout(() => {
      isDuplicateMessage()
    }, 100);
  };

  //复制成功
  const copySuccess = (data) => {
    let prev = JSON.parse(JSON.stringify(data));
    if (prev?.length > getMax()) {
      message.warning({
        content: verifyAdmin(myInfo) ? (
          <span>
            核查对象数量已达上限{getMax()}个，如需支持更多核查对象请
            {getContactBusinessDom()}
            升级订阅方案
          </span>
        ) : (
          <span>
            核查对象数量已达上限{getMax()}
            个，如需支持更多核查对象请联系
            {getAdminsDom()}
            升级订阅方案
          </span>
        ),
      });
      prev = prev?.slice(0, getMax());
    }
    setVerificationList(JSON.parse(JSON.stringify(prev)));
    setTimeout(() => {
      isDuplicateMessage()
    }, 100);
    setCopyVisible(false);
  };
  //查看公司 自然人 信息
  const showInfoHandle = async (record) => {
    const item = {
      shareholderId: { val: record.id },
      name: { val: record.name },
      s_type: { val: record.type },
      click_all: true,
    };
    if (record.type === 2) {
      const { data } = await projectApi.getPersonId(id, {
        name: record.name,
        company_id: record.id,
      });
      item.shareholderId.val = data.person_id;
    }
    showInfoFunc(item, item, "主要股东", id, "name");
  };

  //添加成功回调
  const creatSuccess = (node, isMultiple) => {
    setVerificationList((prev) => {
      if (isMultiple) {
        prev = [...prev, ...node];
      } else {
        node.key = guid();
        prev.push(node);
      }
      if (prev?.length > getMax()) {
        message.warning({
          content: verifyAdmin(myInfo) ? (
            <span>
              核查对象数量已达上限{getMax()}个，如需支持更多核查对象请
              {getContactBusinessDom()}
              升级订阅方案
            </span>
          ) : (
            <span>
              核查对象数量已达上限{getMax()}
              个，如需支持更多核查对象请联系
              {getAdminsDom()}
              升级订阅方案
            </span>
          ),
        });
        prev = prev?.slice(0, getMax());
      } else {
        message.success("添加成功");
      }
      // prev = uniqueArray(prev, ["id", "s_type", "name", "type"]);
      return [...prev];
    });
    setTimeout(() => {
      isDuplicateMessage()
    }, 100);
    // setAddVisible(false);
  };

  //删除
  const deleteItem = (node) => {
    setSelectItemId(node.key);
    MessageModal({
      type: "warning",
      description: "确定要删除该核查对象吗?",
      okText: "删除",
      title: "删除核查对象",
      onOk: () => {
        setVerificationList((prev) => {
          prev = prev.filter((_) => _.key !== node.key);
          return [...prev];
        });
        setSelectItemId(null);
      },
      onCancel: () => {
        setSelectItemId(null);
      },
    });
  };

  //全部删除
  const clearAllHandle = () => {
    MessageModal({
      type: "warning",
      description: "确定要清除所有核查对象吗?",
      title: "清除",
      onOk: () => {
        setVerificationList([]);
      },
    });
  };

  const imgSrc = (type) => {
    if (type === "复制") {
      return icon3;
    } else if (type === "上传") {
      return icon1;
    } else if (type === "新增") {
      return icon2;
    }
  };
  const columns = [
    {
      title: "序号",
      dataIndex: "index",
      key: "index",
      width: "80px",
      align: "center",
      render: (text, record, index) => <>{record.index}</>,
    },
    {
      title: "核查对象类型",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "15%",
      render: (text, record, index) => (
        <>
          {record.type_desc ||
            (record.type === 1 ? "法人" : record.type === 2 ? "自然人" : "")}
        </>
      ),
    },
    {
      title: "核查对象分类",
      dataIndex: "s_type",
      key: "s_type",
      align: "center",
      width: "20%",
    },
    {
      title: "核查对象",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => {
        if (record.is_valid) {
          return (
            <span style={{ color: "red" }}>
              {(text || "") + `（${record.msg}）`}
            </span>
          );
        }
        return (
          <a
            onClick={(e) => {
              e.stopPropagation();
              showInfoHandle(record);
            }}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: "操作",
      dataIndex: "caozuo",
      key: "caozuo",
      width: "100px",
      align: "center",
      render: (text, record, index) => (
        <Button
          type="link"
          onClick={() => {
            deleteItem(record);
          }}
        >
          删除
        </Button>
      ),
    },
  ];

  return (
    <div className="createContent">
      <div className="createBox">
        <div className="createStepBox">
          {["添加核查对象", "设置核查规则"].map((item, index) => (
            <div
              className={
                index + 1 === stepIndex
                  ? "createStepItem activeCreateStepItem"
                  : "createStepItem"
              }
              key={index}
            >
              <div className="line"></div>
              {item}
            </div>
          ))}
        </div>

        <div className="createContentBox" ref={ref}>
          {/* 核查对象 */}
          {stepIndex === 1 &&
            (verificationList?.length === 0 ? (
              <div className="noData">
                <MyEmpty description="还没有添加核查对象"></MyEmpty>
                <div className="createBtnBox">
                  {(isFirst ? ["上传", "新增"] : ["复制", "上传", "新增"]).map(
                    (item) => (
                      <div
                        key={item}
                        className="createBtnItem"
                        onClick={() => {
                          handleFunc(item);
                        }}
                      >
                        <ReactSVG
                          className="createBtnItemIcon"
                          src={imgSrc(item)}
                        ></ReactSVG>
                        {item}
                      </div>
                    )
                  )}
                </div>
              </div>
            ) : (
              <div className="tableContentBox">
                <div className="iconBox">
                  <Tooltip placement="top" title="全部删除" arrowPointAtCenter>
                    <ReactSVG
                      className="clearAll"
                      onClick={() => clearAllHandle(true)}
                      src={deleteIcon}
                    ></ReactSVG>
                  </Tooltip>

                  <Tooltip placement="top" title="上传" arrowPointAtCenter>
                    <VerticalAlignTopOutlined
                      onClick={() => setFileVisible(true)}
                    />
                  </Tooltip>

                  <Tooltip placement="top" title="新增" arrowPointAtCenter>
                    <PlusCircleOutlined
                      onClick={() => {
                        setAddVisible(true);
                      }}
                    />
                  </Tooltip>
                </div>
                <Table
                  scroll={{
                    y: size?.height ? `${size?.height - 100}px` : null,
                  }}
                  style={{ marginBottom: "15px" }}
                  locale={{
                    emptyText: <MyEmpty></MyEmpty>,
                  }}
                  rowClassName={(item) => {
                    let className = "";
                    if (item.key === getSelectItemId()) {
                      className = "selectItem";
                    }
                    return className;
                  }}
                  className="myFormTable"
                  bordered={true}
                  pagination={false}
                  columns={columns}
                  dataSource={verificationList.map((_, index) => {
                    _.index = index + 1;
                    return _;
                  })}
                />
              </div>
            ))}
          {/* 核查规则 */}
          {stepIndex === 2 && (
            <div className="ruleContentBox">
              <HeaderTitle title="核查范围"></HeaderTitle>
              <div className="itemContentBox" style={{ marginBottom: "0" }}>
                {rangeOptionsList.map((_) => (
                  <div
                    key={_}
                    className={
                      rangeList?.includes(_)
                        ? "checkBox checkedBox"
                        : "checkBox"
                    }
                    onClick={() => {
                      setRangeList((prev) => {
                        if (prev?.includes(_)) {
                          prev = prev.filter((__) => __ !== _);
                        } else {
                          prev.push(_);
                        }
                        return [...prev];
                      });
                    }}
                  >
                    <span className="iconCircle"><CheckOutlined /></span>
                    {_}
                  </div>
                ))}
              </div>
              <HeaderTitle title="核查关联类型"></HeaderTitle>
              <div className="itemContentBox">
                {associationTypeOptions.map((_) => (
                  <div className="checkboxItem" key={_}>
                    <Checkbox
                      disabled={_ === "股权关系/历史股权关系"}
                      checked={associationTypes?.includes(_)}
                      onChange={(e) => {
                        if (_ === "股权关系/历史股权关系") {
                          return;
                        }
                        setAssociationTypes((prev) => {
                          if (e.target.checked) {
                            prev.push(_);
                          } else {
                            prev = prev.filter((__) => __ !== _);
                          }
                          return [...prev];
                        });
                      }}
                    >
                      {_}
                    </Checkbox>
                  </div>
                ))}
              </div>
              <HeaderTitle title="核查对象穿透股权比例"></HeaderTitle>
              <div className="itemContentBox">
                <div className="numberInputBox">
                  持股比例不低于
                  <Input
                    className="numberInput"
                    status={inputError ? "error" : null}
                    type="text"
                    value={shareholding}
                    onChange={(e) => {
                      const value = e.target.value
                        ?.replace(/[^\d^\.]+/g, "")
                        .replace(".", "$#$")
                        .replace(/\./g, "")
                        .replace("$#$", ".");
                      if (Number(value) > 100) {
                        setInputErrorMsg("最大输入100");
                        setInputError(true);
                      } else if (Number(value) < 0.01) {
                        setInputErrorMsg("最小输入0.01");
                        setInputError(true);
                      } else {
                        setInputError(false);
                      }
                      setShareholding(value);
                    }}
                    // onKeyDown={(e) => {
                    //   const prohibitInput = ["-", "e", "+", "E"];
                    //   if (prohibitInput.includes(e.key)) {
                    //     e.preventDefault();
                    //     return false
                    //   }
                    // }}
                  />
                  %
                  {inputError && (
                    <div className="inputErrorMsgBox">{inputErrorMsg}</div>
                  )}
                </div>
              </div>
              <HeaderTitle title="核查对象穿透层级"></HeaderTitle>
              <div className="itemContentBox">
                <Select
                  style={{
                    width: "calc(100% - 42px)",
                    marginLeft: "14px",
                  }}
                  value={hierarchy}
                  onChange={setHierarchy}
                  options={[
                    {
                      value: 1,
                      label: "一层",
                    },
                    {
                      value: 2,
                      label: "二层",
                    },
                    {
                      value: 3,
                      label: "三层",
                    },
                    {
                      value: 4,
                      label: "四层",
                    },
                    // {
                    //   value: 5,
                    //   label: "五层",
                    // },
                    // {
                    //   value: 6,
                    //   label: "六层",
                    // },
                    // {
                    //   value: 7,
                    //   label: "七层",
                    // },
                    // {
                    //   value: 8,
                    //   label: "八层",
                    // },
                    // {
                    //   value: 9,
                    //   label: "九层",
                    // },
                    // {
                    //   value: 10,
                    //   label: "十层",
                    // },
                  ]}
                />
              </div>

              <div className="recoveryBtnBox">
                <Button
                  onClick={() => initRules(initialRules)}
                  icon={<UndoOutlined />}
                  type="primary"
                  className="recoveryBtn"
                >
                  恢复默认
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="createContentBtnBox">
          <Button
            style={{ marginRight: "24px" }}
            onClick={() => {
              prevHandle();
            }}
          >
            {stepIndex === 1 ? "取消" : "上一步"}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              nextHandle();
            }}
            loading={isChecking}
          >
            {stepIndex === 2 ? "开始核查" : "下一步"}
          </Button>
        </div>
      </div>

      <UploadFileDrawer
        loading={upLoading}
        visible={fileVisible}
        onClose={() => setFileVisible(false)}
        successFunc={uploadFileSuccess}
        title="上传核查对象"
        // multiple={true}
      ></UploadFileDrawer>

      <AddDrawer
        visible={addVisible}
        onClose={() => setAddVisible(false)}
        successFunc={creatSuccess}
        getVerificationList={getVerificationList}
        getMax={getMax}
      ></AddDrawer>

      <CopyModal
        visible={copyVisible}
        onClose={() => setCopyVisible(false)}
        successFunc={copySuccess}
      ></CopyModal>

      <UploadModal
        upData={upData}
        visible={uploadVisible}
        onCancel={() => {
          setUploadVisible(false);
        }}
        onOk={() => {
          setUploadVisible(false);
          uploadSuccess();
        }}
      ></UploadModal>
    </div>
  );
};
export default CreateContent;
