import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.variable.min.css";
import "./index.scss";
import { Provider } from "react-redux";
import { ConfigProvider, message } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import store from "./store/index";
import App from "./App";
import Login from "./view/Login";
import NotFound from "./view/404";
import CompanyHome from "./view/Company/Home";
import BondHome from "./view/BondPages/Home";
import PrivateRoute from "./components/PrivateRoute";
import Invitation from "./view/Invitation";
import { fixArray } from "./unit/unit";
import moment from "moment";
import "moment/locale/zh-cn";
import request from "axios";
import observationFunc from "./unit/observation";
import { getToken } from "./unit";
moment.locale("zh-cn");

fixArray();
const root = ReactDOM.createRoot(document.getElementById("root"));
ConfigProvider.config({
  theme: {
    primaryColor: "#0068B2",
  },
});

const url = `${
  process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "/cdd/"
}compose/get_app_config`;
request.get(url).then((res) => {
  localStorage.setItem("prefix", res.data?.data?.prefix || "");
  localStorage.setItem("sub_url", res.data?.data?.sub_url || "");
  localStorage.setItem("other_sub_url", res.data?.data?.other_sub_url || "");
  localStorage.setItem("sys_url", res.data?.data?.sys_url || "");
  localStorage.setItem("deploy", res.data?.data?.deploy || "SAAS");
  localStorage.setItem("product_name", res.data?.data?.product_name || "");
  // 模块列表 moduleName:模块名称  modulePaths:路由列表  modulePath:路由地址
  if(res.data?.data?.page_visit_time_url){
    let moduleList = [
      {
        moduleName: "首页",
        modulePaths: ["/home"],
      },
      {
        moduleName: "项目管理",
        modulePaths: ["/projectManagement"],
      },
      {
        moduleName: "工具箱",
        modulePaths: ["/toolCabinet"],
      },
      {
        moduleName: "工商信息",
        modulePath: "/businessInformation",
      },
      {
        moduleName: "分支机构",
        modulePath: "/branch",
      },
      {
        moduleName: "主要人员",
        modulePath: "/keyPersonnel",
      },
      {
        moduleName: "主要股东",
        modulePath: "/majorShareholders",
      },
      {
        moduleName: "对外投资",
        modulePath: "/outboundInvestment",
      },
      {
        moduleName: "股权关系",
        modulePath: "/equityRelationship",
      },
      {
        moduleName: "司法案件",
        modulePath: "/judicialCases",
      },
      {
        moduleName: "底稿管理",
        modulePath: "/reportManagement",
      },
      {
        moduleName: "商标",
        modulePath: "/trademark",
      },
      {
        moduleName: "专利",
        modulePath: "/patents",
      },
      {
        moduleName: "作品著作",
        modulePath: "/copyrightWorks",
      },
      {
        moduleName: "软件著作",
        modulePath: "/softwareWorks",
      },
      {
        moduleName: "资质证书",
        modulePath: "/qualification",
      },
      {
        moduleName: "行政许可",
        modulePath: "/administrativeLicensing",
      },
      {
        moduleName: "行政处罚",
        modulePath: "/punishment",
      },
      {
        moduleName: "动产抵质押",
        modulePath: "/movableProperty",
      },
      {
        moduleName: "不动产抵押",
        modulePath: "/landMortgage",
      },
      {
        moduleName: "关联方管理",
        modulePath: "/relatedPartyManagement",
      },
      {
        moduleName: "疑似关联方分析",
        modulePath: "/suspectedRelatedParty",
      },
      {
        moduleName: "司法拍卖",
        modulePath: "/judicialAuction",
      },
      {
        moduleName: "失信被执行人",
        modulePath: "/dishonestPeople",
      },
      {
        moduleName: "被执行人",
        modulePath: "/personSubjectedToExecution",
      },
      {
        moduleName: "新闻舆情",
        modulePath: "/publicSentiment",
      },
      {
        moduleName: "公告舆情",
        modulePath: "/announcementPublicOpinion",
      },
      {
        moduleName: "历史沿革",
        modulePath: "/historicalEvolution",
      },
      {
        moduleName: "招投标",
        modulePath: "/tenderingAndBidding",
      },
      {
        moduleName: "资本运作情况",
        modulePath: "/capitalOperation",
      },
      {
        moduleName: "法律文书",
        modulePath: "/legalInstrument",
      },
      {
        moduleName: "经营分析",
        modulePath: "/businessAnalysis",
      },
      {
        moduleName: "税务分析",
        modulePath: "/taxAnalysis",
      },
      {
        moduleName: "项目概览",
        modulePath: "/company/",
      },
    ];
    observationFunc(
      res.data?.data?.page_visit_time_url,
      "魔方尽调",
      moduleList,
      getToken
    );
  }
  
  root.render(
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        {/* basename="/due" */}
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/404" element={<NotFound />} />
            {/* 邀请页面 */}
            <Route
              path="/invitation"
              element={
                <PrivateRoute>
                  <Invitation />
                </PrivateRoute>
              }
            />
            {/* 详情页面 */}
            <Route
              path="/company/:id"
              element={
                <PrivateRoute>
                  <CompanyHome></CompanyHome>
                </PrivateRoute>
              }
            />
            <Route
              path="/company/:id/*"
              element={
                <PrivateRoute>
                  <CompanyHome></CompanyHome>
                </PrivateRoute>
              }
            />
            {/* ------- */}
            {/* 债券后督 详情页面 */}
            <Route
              path="/bond/:id"
              element={
                <PrivateRoute>
                  <BondHome></BondHome>
                </PrivateRoute>
              }
            />
            <Route
              path="/bond/:id/*"
              element={
                <PrivateRoute>
                  <BondHome></BondHome>
                </PrivateRoute>
              }
            />
            {/* ------- */}
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <App />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
});
