import "./index.scss";
import { Button, Drawer, Select, message, Input } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderTitle from "../../../../../components/HeaderTitle";
import projectApi from "../../../../../apis/project";
import { useGetState } from "ahooks";
import { useSelector } from "react-redux";

const BtnBox = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button
        style={{}}
        onClick={() => {
          props.clearAll();
        }}
        type="primary"
      >
        恢复默认
      </Button>
      <div className="drawerFooterBtnBox">
        <Button
          onClick={() => {
            props.onFinish();
          }}
          type="primary"
          loading={props.loading}
        >
          重新穿透
        </Button>
        <Button
          className="noBg"
          onClick={() => {
            props.onClose();
          }}
        >
          取消
        </Button>
      </div>
    </div>
  );
};

const RulesDrawer = (props) => {
  const { id } = useParams();
  const { defaultRules, visible } = props;
  const [loading, setLoading] = useState(false);
  const [initialRules, setInitialRules] = useState({});
  const projectInfo = useSelector((state) => state.Project.projectInfo);

  const enterpriseTypeOptionsList = [
    "国家机构机关及事业单位",
    "外资企业",
    "自然人",
    "上市公司",
    "基金公司",
    "集体企业及社会团体",
    "股份制有限公司（非上市）",
  ];

  const enterpriseStatusOptions = [
    // "存续",
    "吊销",
    "解散",
    "迁出",
    "注销",
    "其他",
  ];

  const [enterpriseTypeList, setEnterpriseTypeList] = useState([]);
  const [shareholding, setShareholding] = useState(0.01);
  const [hierarchy, setHierarchy] = useState(4);
  const [direction, setDirection] = useState("双向穿透");
  const [enterpriseStatusList, setEnterpriseStatusList] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const [max, setMax, getMax] = useGetState(4);

  const initRules = (rules) => {
    const _rules = JSON.parse(JSON.stringify(rules || {}));
    setEnterpriseTypeList(_rules["终止穿透的企业类型"]?.value || []);
    setShareholding(_rules["终止穿透股东的持股比例"]?.value || 0.01);
    setHierarchy(_rules["穿透层级"]?.value || 3);
    setDirection(_rules["穿透方向"]?.value?.at(0) || "双向穿透");
    setEnterpriseStatusList(_rules["终止穿透的企业状态"]?.value || []);
    setInputError(false);
    setInputErrorMsg("");
    setLoading(false);
  };

  const getInitialRules = () => {
    projectApi.getPenetrationDefaultRules().then((res) => {
      if (res.code === 200) {
        setInitialRules(res.data);
      } else {
        setInitialRules({});
        message.error(res.message);
      }
    });
  };

  const getMaxHierarchy = () => {
    //自定义和ipo项目 限制穿透层级
    if (
      projectInfo?.scene_code === "CDD_SCENE_TYPE_IPO" ||
      projectInfo?.scene_code === "CDD_SCENE_TYPE_CUS"
    ) {
      projectApi.getLicenseNums('["EQUITY_PENETRATION_LVL"]').then((res) => {
        let max = 10;
        if (res.code === 200) {
          max = (res.data?.EQUITY_PENETRATION_LVL || [])?.at(1) || 10;
        }
        setMax(max);
        //之前没穿透过
        if(!defaultRules["穿透层级"]?.value){
          setHierarchy(max)
        }else{
          //之前穿透规则大于授权的
          if(defaultRules["穿透层级"]?.value > max){
            setHierarchy(max)
          }
        }
      });
    }
  };

  useEffect(() => {
    visible && initRules(defaultRules);
    visible && getInitialRules();
    visible && getMaxHierarchy();
  }, [visible]);

  const clearAll = () => {
    initRules(initialRules);
  };

  const onFinish = () => {
    if (enterpriseTypeList.length === 0) {
      message.error("请选择终止穿透的企业类型");
      return;
    }
    // if (enterpriseStatusList.length === 0) {
    //   message.error("请选择终止穿透的企业状态");
    //   return;
    // }
    if (inputError) {
      message.error("请输入正确的终止穿透股东的持股比例");
      return;
    }
    if (!shareholding) {
      message.error("请输入终止穿透股东的持股比例");
      return;
    }
    if (!hierarchy) {
      message.error("请选择穿透层级");
      return;
    }
    if (!direction) {
      message.error("请选择穿透方向");
      return;
    }
    setLoading(true);
    const obj = {};
    Object.keys(defaultRules)?.forEach((key) => {
      let value = "";
      if (key === "穿透层级") {
        value = hierarchy;
      } else if (key === "穿透方向") {
        value = [direction];
      } else if (key === "终止穿透的企业状态") {
        value = enterpriseStatusList;
      } else if (key === "终止穿透的企业类型") {
        value = enterpriseTypeList;
      } else if (key === "终止穿透股东的持股比例") {
        value = Number(shareholding);
      }
      obj[key] = {
        ...(defaultRules[key] || {}),
        value,
      };
    });
    props.successFunc &&
      props.successFunc(obj).catch(() => {
        setLoading(false);
      });
  };

  return (
    <Drawer
      className="RulesDrawer"
      width="600px"
      closable={false}
      title="穿透规则"
      placement="right"
      push={false}
      maskClosable={false}
      keyboard={false}
      onClose={props.onClose}
      visible={props.visible}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          onClick={() => {
            props.onClose();
          }}
        />
      }
      footer={
        <BtnBox
          clearAll={clearAll}
          onClose={props.onClose}
          onFinish={onFinish}
          loading={loading}
        ></BtnBox>
      }
    >
      <Scrollbars
        autoHide={true}
        autoHideTimeout={5000}
        style={{
          height: `100%`,
          width: "100%",
        }}
      >
        <div>
          <HeaderTitle title="终止穿透的企业类型"></HeaderTitle>
          <div className="itemContentBox" style={{ marginBottom: 0 }}>
            {enterpriseTypeOptionsList.map((_) => (
              <div
                key={_}
                className={
                  enterpriseTypeList?.includes(_)
                    ? "checkBox checkedBox"
                    : "checkBox"
                }
                onClick={() => {
                  setEnterpriseTypeList((prev) => {
                    if (prev?.includes(_)) {
                      if (
                        [
                          "国家机构机关及事业单位",
                          "外资企业",
                          "自然人",
                        ].includes(_)
                      ) {
                        return [...prev];
                      }
                      prev = prev.filter((__) => __ !== _);
                    } else {
                      prev.push(_);
                    }
                    return [...prev];
                  });
                }}
                style={{
                  cursor: [
                    "国家机构机关及事业单位",
                    "外资企业",
                    "自然人",
                  ].includes(_)
                    ? "not-allowed"
                    : "pointer",
                }}
              >
                <span className="iconCircle"><CheckOutlined /></span>
                {_}
              </div>
            ))}
          </div>

          <HeaderTitle title="终止穿透的企业状态"></HeaderTitle>
          <div className="itemContentBox" style={{ marginBottom: 0 }}>
            {enterpriseStatusOptions.map((_) => (
              <div
                key={_}
                className={
                  enterpriseStatusList?.includes(_)
                    ? "checkBox checkedBox"
                    : "checkBox"
                }
                onClick={() => {
                  setEnterpriseStatusList((prev) => {
                    if (prev?.includes(_)) {
                      prev = prev.filter((__) => __ !== _);
                    } else {
                      prev.push(_);
                    }
                    return [...prev];
                  });
                }}
              >
                <span className="iconCircle"><CheckOutlined /></span>
                {_}
              </div>
            ))}
          </div>

          {/* <HeaderTitle title="企业状态"></HeaderTitle>
          <div className="itemContentBox">
            {enterpriseStatusOptions.map((_) => (
              <div className="checkboxItem">
                <Checkbox
                  checked={enterpriseStatusList?.includes(_)}
                  onChange={(e) => {
                    setEnterpriseStatusList((prev) => {
                      if (e.target.checked) {
                        prev.push(_);
                      } else {
                        prev = prev.filter((__) => __ !== _);
                      }
                      return [...prev];
                    });
                  }}
                >
                  {_}
                </Checkbox>
              </div>
            ))}
          </div> */}
          <HeaderTitle title="终止穿透股东的持股比例"></HeaderTitle>
          <div className="itemContentBox">
            <div className="numberInputBox">
              持股比例不低于
              <Input
                status={inputError ? "error" : null}
                className="numberInput"
                value={shareholding}
                onChange={(e) => {
                  const value = e.target.value
                    ?.replace(/[^\d^\.]+/g, "")
                    .replace(".", "$#$")
                    .replace(/\./g, "")
                    .replace("$#$", ".");
                  if (Number(value) > 100) {
                    setInputErrorMsg("最大输入100");
                    setInputError(true);
                  } else if (Number(value) < 0.01) {
                    setInputErrorMsg("最小输入0.01");
                    setInputError(true);
                  } else {
                    setInputError(false);
                  }
                  setShareholding(value);
                }}
                type="text"
                // onKeyDown={(e) => {
                //   const prohibitInput = ["-", "e", "+", "E"];
                //   if (prohibitInput.includes(e.key)) {
                //     e.preventDefault();
                //     return false;
                //   }
                // }}
              />
              %
              {inputError && (
                <div className="inputErrorMsgBox">{inputErrorMsg}</div>
              )}
            </div>
          </div>
          <HeaderTitle title="穿透层级"></HeaderTitle>
          <div className="itemContentBox">
            <Select
              style={{
                width: "calc(100% - 42px)",
                marginLeft: "14px",
              }}
              value={hierarchy}
              onChange={setHierarchy}
              options={[
                {
                  value: 1,
                  label: "一层",
                },
                {
                  value: 2,
                  label: "二层",
                },
                {
                  value: 3,
                  label: "三层",
                },
                {
                  value: 4,
                  label: "四层",
                },
                {
                  value: 5,
                  label: "五层",
                },
                {
                  value: 6,
                  label: "六层",
                },
                {
                  value: 7,
                  label: "七层",
                },
                {
                  value: 8,
                  label: "八层",
                },
                {
                  value: 9,
                  label: "九层",
                },
                {
                  value: 10,
                  label: "十层",
                },
              ]?.filter(_=>_.value <= getMax())}
            />
          </div>
          <HeaderTitle title="穿透方向"></HeaderTitle>
          <div className="itemContentBox">
            <Select
              style={{
                width: "calc(100% - 42px)",
                marginLeft: "14px",
              }}
              value={direction}
              onChange={setDirection}
              options={[
                {
                  value: "双向穿透",
                  label: "双向穿透",
                },
                {
                  value: "向上穿透（仅穿透股东）",
                  label: "向上穿透（仅穿透股东）",
                },
                {
                  value: "向下穿透（仅穿透投资）",
                  label: "向下穿透（仅穿透投资）",
                },
              ]}
            />
          </div>
        </div>
      </Scrollbars>
    </Drawer>
  );
};
export default RulesDrawer;
